import {
    ThemeColorVariable,
    ThemeFontSizeVariable,
    ThemeRadiusVariable,
    ThemeSpaceVariable,
} from "@caspeco/casper-ui-library.base-ui.theme";
import { Box } from "@caspeco/casper-ui-library.components.box";
import { Flex } from "@caspeco/casper-ui-library.components.flex";
import { Text } from "@caspeco/casper-ui-library.components.text";
import { useExternalBookingSettings } from "api/api-hooks/use-external-booking-settings";
import BackButton from "components/stepper/back-button";
import { getStepperProgressPercentage } from "components/stepper/stepper-helper";
import { ActiveStep, useActiveStep } from "components/stepper/use-active-step";
import { useBookingStateContext } from "hooks/use-booking-state";
import { usePaymentRule } from "hooks/use-payment-rule";
import { useSafeNavigation } from "hooks/use-safe-navigation";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import { getSearchParams } from "search-params";
import { PaymentRule } from "types/payment";
import i18n from "i18next";

export const Stepper = () => {
    const { activateWebChildren, maxWebGuests } = useExternalBookingSettings().data.unitsMetaData;
    const state = useBookingStateContext();
    const { t } = useTranslation();
    const safeNavigate = useSafeNavigation();
    const activeStep = useActiveStep();
    const paymentRule = usePaymentRule();

    const removeSearchParams = (keys: string[]) => {
        const currentParams = new URLSearchParams(window.location.search);

        keys.forEach((key) => currentParams.delete(key));

        // Navigate with updated search params
        const newSearch = currentParams.toString();
        const newPath = `${window.location.pathname}${newSearch ? `?${newSearch}` : ""}`;
        safeNavigate(newPath);
    };

    const { guests, guestsChildren, date } = state;
    const resetLastStep = () => {
        const resetSearchParamsMap: Record<ActiveStep, string[]> = {
            [ActiveStep.Guests]: [],
            [ActiveStep.GuestsChildren]: ["guests"],
            [ActiveStep.Date]: activateWebChildren ? ["children"] : ["guests"],
            [ActiveStep.Times]: ["date", "r", "start", "end", "timesConfirmed"],
            [ActiveStep.Details]: ["r", "start", "end", "timesConfirmed"],
        };

        const toReset = resetSearchParamsMap[activeStep] || [];
        removeSearchParams(toReset);
    };

    const dateText = date?.locale(i18n.language).format("D MMM YYYY");
    let guestText = t("start.numberGuests", { guests });

    if (guestsChildren) {
        const childrenText = t("start.ofWhich", { children: guestsChildren });
        guestText = `${guestText} ${childrenText}`;
    }

    const { isActivityBooking } = getSearchParams();

    const isPrepayment = paymentRule === PaymentRule.Prepayment;
    const stepContext = [
        {
            condition: ActiveStep.Guests,
            summaryText: [t("stepper.welcome")],
            stepTitle: t("stepper.chooseGuestSize"),
            hideBackButton: true,
        },
        {
            condition: ActiveStep.GuestsChildren,
            summaryText: [guestText],
            stepTitle: t("stepper.chooseGuestChildren"),
        },
        {
            condition: ActiveStep.Date,
            summaryText: [guestText],
            stepTitle: t("stepper.chooseDate"),
        },

        {
            condition: ActiveStep.Times,
            summaryText: [guestText, dateText],
            stepTitle: isActivityBooking ? t("stepper.chooseActivities") : t("stepper.chooseTime"),
        },
        {
            condition: ActiveStep.Details,
            summaryText: [guestText, dateText],
            stepTitle: isPrepayment
                ? t("stepper.confirmDetailsAndPayment")
                : t("stepper.confirmDetails"),
        },
    ];

    const progressbarHeight = "0.25rem";
    const progress = getStepperProgressPercentage(
        state,
        activateWebChildren,
        maxWebGuests,
        state.isTimesConfirmed
    );

    return (
        <Flex flexDirection="column" pb={ThemeSpaceVariable.Large}>
            <Flex
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
            >
                <Box
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    gap={ThemeSpaceVariable.XSmall}
                    fontSize={ThemeFontSizeVariable.Medium}
                >
                    {stepContext.map((step) => {
                        if (step.condition === activeStep) {
                            return (
                                <Flex
                                    key={step.condition}
                                    w="full"
                                    textAlign="center"
                                    align="center"
                                >
                                    {step.hideBackButton ? (
                                        <Flex flex={1} justifyContent="flex-start" />
                                    ) : (
                                        <Flex flex={1} align="center">
                                            <BackButton onClick={resetLastStep} />
                                        </Flex>
                                    )}

                                    <Flex direction="column" align="center" flex={3}>
                                        <Flex
                                            justify="center"
                                            direction="row"
                                            gap={ThemeSpaceVariable.XSmall}
                                            flexWrap="wrap"
                                            align="center"
                                            color={ThemeColorVariable.OnBackgroundSubdued}
                                        >
                                            {step.summaryText.map((text, index) => (
                                                <Fragment key={index}>
                                                    <Text
                                                        key={index}
                                                        as="span"
                                                        fontSize={ThemeFontSizeVariable.Small}
                                                        color={
                                                            ThemeColorVariable.OnBackgroundSubdued
                                                        }
                                                    >
                                                        {text}
                                                        {index !== step.summaryText.length - 1 &&
                                                            ","}
                                                    </Text>
                                                </Fragment>
                                            ))}
                                        </Flex>

                                        <Text fontWeight="bold">{step.stepTitle}</Text>
                                    </Flex>
                                    <Flex flex="1" />
                                </Flex>
                            );
                        }
                        return null;
                    })}
                </Box>
            </Flex>

            <Box
                position="relative"
                width="full"
                height={progressbarHeight}
                mt={ThemeSpaceVariable.Small}
                bg={ThemeColorVariable.BackgroundDisabled}
                borderRadius={ThemeRadiusVariable.XSmall}
                overflow="hidden"
            >
                <Box width={`${progress}%`} height="full" background={ThemeColorVariable.Primary} />
            </Box>
        </Flex>
    );
};
